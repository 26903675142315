import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/sliders/sliders.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import SliderHeroComponent from "../../../library/components/slider/react/_preview/SliderHeroComponent"

// Preview
import SliderControlsInsidePreview from "../../../library/components/slider/react/_preview/controls-inside"
import SliderControlsOutsidePreview from "../../../library/components/slider/react/_preview/controls-outside"
import SliderIndicatorsOutsidePreview from "../../../library/components/slider/react/_preview/indicators-outside"
import SliderIndicatorsInsidePreview from "../../../library/components/slider/react/_preview/indicators-inside"
import SliderIndicatorsControlsInsidePreview from "../../../library/components/slider/react/_preview/indicators-controls-inside"
import SliderCardPreview from "../../../library/components/slider/react/_preview/card"
import SliderTestimonialPreview from "../../../library/components/slider/react/_preview/testimonial"

// All
const SliderControlsInsideJsx = require("!!raw-loader!../../../library/components/slider/react/controls-inside.jsx")
const SliderControlsInsideHTML = require("!!raw-loader!../../../library/components/slider/html/controls-inside.html")

const SliderControlsOutsideJsx = require("!!raw-loader!../../../library/components/slider/react/controls-outside.jsx")
const SliderControlsOutsideHTML = require("!!raw-loader!../../../library/components/slider/html/controls-outside.html")

const SliderIndicatorsOutsideJsx = require("!!raw-loader!../../../library/components/slider/react/indicators-outside.jsx")
const SliderIndicatorsOutsideHTML = require("!!raw-loader!../../../library/components/slider/html/indicators-outside.html")

const SliderIndicatorsInsideJsx = require("!!raw-loader!../../../library/components/slider/react/indicators-inside.jsx")
const SliderIndicatorsInsideHTML = require("!!raw-loader!../../../library/components/slider/html/indicators-inside.html")

const SliderIndicatorsControlsInsideJsx = require("!!raw-loader!../../../library/components/slider/react/indicators-controls-inside.jsx")
const SliderIndicatorsControlsInsideHTML = require("!!raw-loader!../../../library/components/slider/html/indicators-controls-inside.html")

const SliderCardJsx = require("!!raw-loader!../../../library/components/slider/react/card.jsx")
const SliderCardHTML = require("!!raw-loader!../../../library/components/slider/html/card.html")

const SliderTestimonialJsx = require("!!raw-loader!../../../library/components/slider/react/testimonial.jsx")
const SliderTestimonialHTML = require("!!raw-loader!../../../library/components/slider/html/testimonial.html")

export default function SlidersPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "SliderControlsInside",
      title: "Controls Inside Slider",
      active_tab: 1,
    },
    {
      component_name: "SliderControlsOutside",
      title: "Controls Outside",
      active_tab: 1,
    },
    {
      component_name: "SliderIndicatorsOutside",
      title: "Indicator Outside",
      active_tab: 1,
    },
    {
      component_name: "SliderIndicatorsInside",
      title: "Indicator Inside",
      active_tab: 1,
    },

    {
      component_name: "SliderIndicatorsControlsInside",
      title: "Indicator & Controls Inside",
      active_tab: 1,
    },
    {
      component_name: "SliderCard",
      title: "Card",
      active_tab: 1,
    },
    {
      component_name: "SliderTestimonial",
      title: "Testimonial",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Sliders - WindUI Component Library"
        ogtitle="Tailwind CSS Sliders - WindUI Component Library"
        description="Slider components are design slideshow elements used to display images & videos on your website, showing one slide at a time. Built with Tailwind CSS by WindUI."
        ogdescription="Slider components are design slideshow elements used to display images & videos on your website, showing one slide at a time. Built with Tailwind CSS by WindUI."
        ogimage={ogImage}
        url="components/sliders/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Sliders, glidejs, Sliders, Slider Component, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Sliders</h1>
          <p>
            A slider is a design element used to display images and videos on
            your website. It works like a slideshow, showing one image or video
            at a time. The content changes after a few seconds or when the
            visitor clicks to view the next one.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <SliderHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="SliderControlsInside">
            Controls Inside <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="SliderControlsInside"
            HtmlComponent={SliderControlsInsideHTML.default}
            JsxComponent={SliderControlsInsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <SliderControlsInsidePreview
                SliderControlsInside={
                  activeTabs[0].active_tab === 1
                    ? SliderControlsInsideHTML.default
                    : SliderControlsInsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SliderControlsOutside">
            Controls Outside <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="SliderControlsOutside"
            HtmlComponent={SliderControlsOutsideHTML.default}
            JsxComponent={SliderControlsOutsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-16 sm:p-16 sm:pb-16 [&>*]:w-full">
              <SliderControlsOutsidePreview
                SliderControlsOutside={
                  activeTabs[1].active_tab === 1
                    ? SliderControlsOutsideHTML.default
                    : SliderControlsOutsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SliderIndicatorsOutside">
            Indicator Outside <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="SliderIndicatorsOutside"
            HtmlComponent={SliderIndicatorsOutsideHTML.default}
            JsxComponent={SliderIndicatorsOutsideJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <SliderIndicatorsOutsidePreview
                SliderIndicatorsOutside={
                  activeTabs[2].active_tab === 1
                    ? SliderIndicatorsOutsideHTML.default
                    : SliderIndicatorsOutsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SliderIndicatorsInside">
            Indicator Inside <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="SliderIndicatorsInside"
            HtmlComponent={SliderIndicatorsInsideHTML.default}
            JsxComponent={SliderIndicatorsInsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <SliderIndicatorsInsidePreview
                SliderIndicatorsInside={
                  activeTabs[3].active_tab === 1
                    ? SliderIndicatorsInsideHTML.default
                    : SliderIndicatorsInsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SliderIndicatorsControlsInside">
            Indicator & Controls Inside{" "}
            <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="SliderIndicatorsControlsInside"
            HtmlComponent={SliderIndicatorsControlsInsideHTML.default}
            JsxComponent={SliderIndicatorsControlsInsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <SliderIndicatorsControlsInsidePreview
                SliderIndicatorsControlsInside={
                  activeTabs[4].active_tab === 1
                    ? SliderIndicatorsControlsInsideHTML.default
                    : SliderIndicatorsControlsInsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SliderCard">
            Card <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="SliderCard"
            HtmlComponent={SliderCardHTML.default}
            JsxComponent={SliderCardJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <SliderCardPreview
                SliderCard={
                  activeTabs[5].active_tab === 1
                    ? SliderCardHTML.default
                    : SliderCardJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SliderTestimonial">
            Testimonial <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="SliderTestimonial"
            HtmlComponent={SliderTestimonialHTML.default}
            JsxComponent={SliderTestimonialJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <SliderTestimonialPreview
                SliderTestimonial={
                  activeTabs[6].active_tab === 1
                    ? SliderTestimonialHTML.default
                    : SliderTestimonialJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use the slider component to view multiple pieces of content that
            occupies a single coveted space:
          </p>
          <ul>
            <li>
              To enable more than one piece of content to occupy the same piece
              of real estate on the app interface.
            </li>
            <li>
              To display a group of content on the same level (set of images,
              users testimonials etc.)
            </li>
          </ul>
          <p>
            The sliders functionality is implemented using{" "}
            <a href="https://glidejs.com/" target="_blank" rel="external">
              GlideJS
            </a>
            . GlideJS CDN is used in all HTML files and the React components are
            using the{" "}
            <a
              href="https://github.com/glidejs/glide"
              target="_blank"
              rel="external"
            >
              @glidejs/glide
            </a>{" "}
            as a dependency.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>The slider component consists of the following elements:</p>

          <ul>
            <li>The list of elements that will slide</li>
            <li>
              The navigation arrows (3rem (48px) in width and height) to provide
              enough space for reliable interaction
            </li>
            <li>
              The slide indicators (2.5rem(40px) touch area with 0.5rem(8px)
              dots) that displays the total number of slides and the active
              slide.
            </li>
          </ul>
          <p>
            Both indicators and navigation arrows can be placed within or
            outside the slide area.
          </p>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            All images should use the <code>alt</code> to be able to be
            accessible from screen readers.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
